import React, {useState} from 'react';
import NavBar from '../NavBar';

const Donate =()=> {

  const [menu, setMenu] = useState(false);

  return(
    <>
      <NavBar/>
    </>
  );
}

export default Donate;