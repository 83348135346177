import React, {useState} from 'react';
import NavBar from '../NavBar';
import Footer from '../Footer';

const Impact =()=> {

  const [menu, setMenu] = useState(false);

  return(
    <>
      <NavBar/>
    </>
  );
}

export default Impact;

